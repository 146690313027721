/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum LikeAction {
  dislike = "dislike",
  like = "like",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
